<template>
  <v-card flat :loading="loading" height="100%" outlined rounded="lg">
    <v-card-title class="mb-2">
      {{ $t('customer.orders.defineApprovalRound') }}
    </v-card-title>
    <v-form :disabled="loading">
      <v-row>
        <template v-if="isManualCreationMode">
          <v-col :lg="6" :md="12">
            <v-card flat :loading="loading">
              <v-card-title class="text-title font-weight-semibold mb-2">
                {{ $t('customer.orders.defineOrderApprovalRound') }}
              </v-card-title>
              <v-card-text>
                <validation-provider
                  rules="required"
                  :name="$t('customer.orders.orderApprovalWorkflow')"
                  v-slot="{ errors }"
                >
                  <cz-autocomplete
                    v-model="order.orderApprovalWorkflowId"
                    :items="
                      accountWorkflows.filter(
                        (item) => item.type === 'order-approval-workflow'
                      )
                    "
                    :label="$t('customer.orders.orderApprovalWorkflow')"
                    item-text="name"
                    item-value="_id"
                    :placeholder="$t('customer.orders.selectApprovalWorkflow')"
                    @change="onSelectedOrderApprovalWorkflowChange"
                    :clearable="!readonly"
                    :error-messages="errors"
                    :readonly="readonly"
                  >
                    <template #selection="{ item }">
                      <div
                        class="d-flex align-center justify-space-between full-width"
                      >
                        <div class="text-body-1 mb-1">{{ item.name }}</div>
                        <cz-chip
                          color="primary"
                          circle-edges
                          small
                          :text="
                            $t('customer.workflowManagementPage.stepsCount', [
                              item.steps.length
                            ])
                          "
                          style="max-width: auto"
                        />
                      </div>
                    </template>
                    <template #item="{ item }">
                      <div
                        class="d-flex align-center justify-space-between full-width py-4"
                        style="column-gap: 20px"
                      >
                        <div class="text-body-1 mb-1">{{ item.name }}</div>
                        <cz-chip
                          color="primary"
                          circle-edges
                          small
                          :text="
                            $t('customer.workflowManagementPage.stepsCount', [
                              item.steps.length
                            ])
                          "
                          style="max-width: auto"
                        />
                      </div>
                    </template>
                  </cz-autocomplete>
                </validation-provider>
                <v-stepper
                  v-if="order.orderApprovalWorkflowSteps.length"
                  outlined
                  flat
                  color="primary"
                  alt-labels
                >
                  <v-stepper-header class="flex-nowrap">
                    <template
                      v-for="(step, index) in order.orderApprovalWorkflowSteps"
                    >
                      <v-stepper-step
                        :key="step.accountWorkflowStepId"
                        :icon="mdiCheck"
                        :step="step.stepNumber"
                        :complete-icon="
                          step.approverUserId ? mdiCheck : mdiCancel
                        "
                        complete
                        color="primary"
                      >
                        {{ step.stepTitle }}
                      </v-stepper-step>
                      <v-divider
                        v-if="
                          index < order.orderApprovalWorkflowSteps.length - 1
                        "
                        :key="`${step.accountWorkflowStepId}_divider`"
                      />
                    </template>
                  </v-stepper-header>
                </v-stepper>
                <v-form class="position-relative" :disabled="loading">
                  <v-row class="overflow-x-hidden mt-4" no-gutters>
                    <v-col
                      :lg="12"
                      :md="12"
                      :sm="12"
                      v-for="step in order.orderApprovalWorkflowSteps"
                      :key="step._id"
                    >
                      <cz-form-field
                        :label="step.stepTitle"
                        :required="!readonly"
                      >
                        <validation-provider
                          rules="required"
                          :name="step.stepTitle"
                          v-slot="{ errors }"
                        >
                          <cz-autocomplete
                            v-model="step.approverUserId"
                            :error-messages="errors"
                            :items="getApproverForWorkflowStep(step)"
                            item-text="fullName"
                            item-value="_id"
                            :placeholder="
                              step.approverRole === 'customer-authorizer'
                                ? $t('customer.orders.selectOrderAuthorizer')
                                : $t('customer.orders.selectOrderPurchaser')
                            "
                            :clearable="!readonly"
                            :readonly="readonly"
                          >
                            <template #selection="{ item }">
                              <div
                                class="d-flex align-center"
                                style="column-gap: 20px"
                              >
                                <cz-avatar
                                  class="mx-0"
                                  :size="35"
                                  color="primary"
                                  :name="item.fullName"
                                  initials-color="white"
                                  initials-size="font-weight-semibold text-subtitle-1 text-uppercase"
                                />
                                <span class="text-body-1">
                                  {{ item.fullName }}</span
                                >
                              </div>
                            </template>
                            <template #item="{ item }">
                              <v-list-item-avatar>
                                <cz-avatar
                                  :size="35"
                                  color="primary"
                                  :name="item.fullName"
                                  initials-color="white"
                                  initials-size="font-weight-semibold text-subtitle-1 text-uppercase"
                                />
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>
                                  {{ item.fullName }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </template>
                          </cz-autocomplete>
                        </validation-provider>
                      </cz-form-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- <v-col :cols="2" class="d-flex justify-center">
            <v-divider vertical class="mx-auto" />
          </v-col> -->
        </template>
        <v-col :lg="6" :md="12">
          <v-card :loading="loading" flat>
            <v-card-title class="text-title font-weight-semibold mb-2">
              {{ $t('customer.orders.defineOrderSubmissionApprovalRound') }}
            </v-card-title>
            <v-card-text>
              <validation-provider
                rules="required"
                :name="$t('customer.orders.orderApprovalWorkflow')"
                v-slot="{ errors }"
              >
                <cz-autocomplete
                  v-model="order.orderSubmissionWorkflowId"
                  :items="
                    accountWorkflows.filter(
                      (item) => item.type === 'order-submission-workflow'
                    )
                  "
                  :label="$t('customer.orders.orderApprovalWorkflow')"
                  item-text="name"
                  item-value="_id"
                  :clearable="!readonly"
                  :placeholder="$t('customer.orders.selectApprovalWorkflow')"
                  @change="onSelectedOrderSubmissionlWorkflowChange"
                  :error-messages="errors"
                  :readonly="readonly"
                >
                  <template #selection="{ item }">
                    <div
                      class="d-flex align-center justify-space-between full-width"
                    >
                      <div class="text-body-1 mb-1">{{ item.name }}</div>
                      <cz-chip
                        color="primary"
                        circle-edges
                        small
                        :text="
                          $t('customer.workflowManagementPage.stepsCount', [
                            item.steps.length
                          ])
                        "
                        style="max-width: auto"
                      />
                    </div>
                  </template>
                  <template #item="{ item }">
                    <div
                      class="d-flex align-center justify-space-between full-width py-4"
                      style="column-gap: 20px"
                    >
                      <div class="text-body-1 mb-1">{{ item.name }}</div>
                      <cz-chip
                        color="primary"
                        circle-edges
                        small
                        :text="
                          $t('customer.workflowManagementPage.stepsCount', [
                            item.steps.length
                          ])
                        "
                        style="max-width: auto"
                      />
                    </div>
                  </template>
                </cz-autocomplete>
              </validation-provider>
              <v-stepper
                outlined
                flat
                width="100%"
                color="primary"
                v-if="order.orderSubmissionWorkflowSteps.length"
                alt-labels
              >
                <v-stepper-header class="flex-nowrap">
                  <template
                    v-for="(step, index) in order.orderSubmissionWorkflowSteps"
                  >
                    <v-stepper-step
                      :key="step.accountWorkflowStepId"
                      :icon="mdiCheck"
                      :step="step.stepNumber"
                      :complete-icon="
                        step.approverUserId ? mdiCheck : mdiCancel
                      "
                      complete
                      color="primary"
                    >
                      {{ step.stepTitle }}
                    </v-stepper-step>
                    <v-divider
                      v-if="
                        index < order.orderSubmissionWorkflowSteps.length - 1
                      "
                      :key="`${step.accountWorkflowStepId}_divider`"
                    />
                  </template>
                </v-stepper-header>
              </v-stepper>
              <v-form class="position-relative" :disabled="loading">
                <v-row class="overflow-x-hidden mt-4" no-gutters>
                  <v-col
                    :lg="12"
                    :md="12"
                    :sm="12"
                    v-for="step in order.orderSubmissionWorkflowSteps"
                    :key="step._id"
                  >
                    <cz-form-field
                      :label="step.stepTitle"
                      :required="!readonly"
                    >
                      <validation-provider
                        rules="required"
                        :name="step.stepTitle"
                        v-slot="{ errors }"
                      >
                        <cz-autocomplete
                          v-model="step.approverUserId"
                          :error-messages="errors"
                          :items="getApproverForWorkflowStep(step)"
                          item-text="fullName"
                          item-value="_id"
                          :placeholder="
                            step.approverRole === 'customer-authorizer'
                              ? $t('customer.orders.selectOrderAuthorizer')
                              : $t('customer.orders.selectOrderPurchaser')
                          "
                          :clearable="!readonly"
                          :readonly="readonly"
                        >
                          <template #selection="{ item }">
                            <div
                              class="d-flex align-center"
                              style="column-gap: 20px"
                            >
                              <cz-avatar
                                class="mx-0"
                                :size="35"
                                color="primary"
                                :name="item.fullName"
                                initials-color="white"
                                initials-size="font-weight-semibold text-subtitle-1 text-uppercase"
                              />
                              <span class="text-body-1">
                                {{ item.fullName }}</span
                              >
                            </div>
                          </template>
                          <template #item="{ item }">
                            <v-list-item-avatar>
                              <cz-avatar
                                :size="35"
                                color="primary"
                                :name="item.fullName"
                                initials-color="white"
                                initials-size="font-weight-semibold text-subtitle-1 text-uppercase"
                              />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ item.fullName }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </cz-autocomplete>
                      </validation-provider>
                    </cz-form-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';
import { CzFormField, CzAutocomplete, CzAvatar, CzChip } from '@/components';
import { userHasOneOfRolesWithName } from '@/shared/services/rbac/rbac.service';
import { mdiCheck, mdiCancel } from '@mdi/js';
import RoleType from '@/shared/types/RoleType';
export default {
  name: 'OrderApprovals',
  components: {
    CzFormField,
    CzAutocomplete,
    CzAvatar,
    CzChip
  },
  props: {
    value: {
      type: Object
    },
    loading: {
      type: Boolean
    },
    accountWorkflows: {
      type: Array,
      default: () => []
    },
    approvers: {
      type: Object
    },
    currentUser: {
      type: Object
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isManualCreationMode() {
      return this.order?.creationType === 'manual';
    }
  },
  created() {
    this.order = cloneDeep(this.value);
  },
  data() {
    return {
      order: null,
      mdiCheck,
      mdiCancel
    };
  },

  methods: {
    getApproverForWorkflowStep(workflowStep) {
      if (!workflowStep.approverRole || !this.approvers) {
        return [];
      }
      return this.approvers[workflowStep.approverRole]?.map(
        (item) => item.user
      );
    },
    onSelectedOrderApprovalWorkflowChange(value) {
      this.order.orderApprovalWorkflowSteps = [];
      if (!value) {
        return;
      }

      const orderApprovalWorkflow = this.accountWorkflows.find(
        (item) => item._id === value
      );
      for (const step of orderApprovalWorkflow.steps) {
        this.order.orderApprovalWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: this.order._id,
          stepNumber: step.stepNumber,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }
    },
    onSelectedOrderSubmissionlWorkflowChange(value) {
      this.order.orderSubmissionWorkflowSteps = [];
      if (!value) {
        return;
      }

      const orderSubmissionWorkflow = this.accountWorkflows.find(
        (item) => item._id === value
      );
      for (const step of orderSubmissionWorkflow.steps) {
        this.order.orderSubmissionWorkflowSteps.push({
          _id: step._id,
          approverUserId: '',
          accountWorkflowStepId: step._id,
          orderId: this.order._id,
          stepNumber: step.stepNumber,
          // accountWorkflowStep: step,
          approverRole: step.approverRole,
          approverRoleText: step.approverRoleText,
          stepTitle: step.stepTitle,
          stepType: step.stepType,
          stepTypeText: step.stepTypeText,
          workflowId: step.workflowId,
          accountId: step.accountId
        });
      }

      const currentUserIsPurchaser = userHasOneOfRolesWithName(
        this.currentUser,
        RoleType.CUSTOMER_PURCHASER
      );

      if (currentUserIsPurchaser) {
        const purchaserStep = this.order.orderSubmissionWorkflowSteps.find(
          (item) => item.approverRole === RoleType.CUSTOMER_PURCHASER
        );

        purchaserStep.approverUserId = this.currentUser._id;
      }
    }
  },
  watch: {
    deep: true,
    value: {
      handler(value) {
        if (!isEqual(value, this.order)) {
          this.order = cloneDeep(value);
        }
      }
    },
    order: {
      deep: true,
      handler(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
